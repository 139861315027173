<template>
  <vx-card class="p-3 pt-6 pb-6 md:p-6">
    <div class="mt-5">
      <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        finishButtonText="Submit"
        @on-complete="formSubmitted"
        @changeTab="next"
      >
        <tab-content
          title="General Settings"
          class="mb-5"
          route="/exam/create-exam/general-settings"
        >
        </tab-content>

        <tab-content
          title="Access Attendance"
          class="mb-5"
          route="/exam/create-exam/attendance-access"
        >
        </tab-content>

        <tab-content
          title="Questions Manager"
          class="mb-5"
          route="/exam/create-exam/questions-manager"
        >
        </tab-content>

        <tab-content
          title="Configuration"
          class="mb-5"
          route="/exam/create-exam/configuration"
        >
        </tab-content>

        <tab-content
          title="Exam Info"
          class="mb-5"
          route="/exam/create-exam/exam-info"
        >
        </tab-content>
        <router-view></router-view>
      </form-wizard>
    </div>
  </vx-card>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    FormWizard,
    TabContent
  },
  methods: {
    formSubmitted () {
      // alert('Form submitted!')
    },
    next (oldIndex, newIndex) {
      console.log(oldIndex, newIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
