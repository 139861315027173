import { render, staticRenderFns } from "./createExam.vue?vue&type=template&id=63bc9532&scoped=true"
import script from "./createExam.vue?vue&type=script&lang=js"
export * from "./createExam.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63bc9532",
  null
  
)

export default component.exports